import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "",
        name: 'home',
        component: () => import("../views/Home.vue")
      },
      {
        path: "/role",
        name: "role",
        component: () => import("../views/sys/Role.vue")
      },
      {
        path: "/rescue",
        name: "rescue",
        component: () => import("../views/this/RescueList.vue")
      },
      {
        path: "/activity",
        name: "activity",
        component: () => import("../views/this/EventActivity.vue")
      },
      {
        path: "/sign",
        name: "sign",
        component: () => import("../views/this/EventSign.vue")
      },
      {
        path: "/manager",
        name: "manager",
        component: () => import("../views/sys/Manager.vue")
      },
      {
        path: "/navigation",
        name: "navigation",
        component: () => import("../views/sys/Navigation.vue")
      },
      {
        path: "/siteconfig",
        name: "siteconfig",
        component: () => import("../views/sys/SiteConfig.vue")
      },
      {
        path: "/banner",
        name: "banner",
        component: () => import("../views/sys/Banner.vue")
      },
      {
        path: "/articleInfo",
        name: "articleInfo",
        component: () => import("../views/sys/ArticleInfo.vue")
      },
      {
        path: "/articleStatic/:id",
        name: "articleStatic",
        component: () => import("../views/sys/ArticleStatic.vue"),
      },
      {
        path: "/data",
        name: "data",
        component: () => import("../views/sys/Data.vue")
      },
      {
        path: "/category",
        name: "category",
        component: () => import("../views/sys/Category.vue")
      },
      {
        path: "/area",
        name: "area",
        component: () => import("../views/sys/Area.vue")
      },
      {
        path: "/userlist",
        name: "userlist",
        component: () => import("../views/user/Userlist.vue")
      },
      {
        path: "/collect_year",
        name: "collect_year",
        component: () => import("../views/collect/Year.vue")
      },
      {
        path: "/shopGoods",
        name: "shopGoods",
        component: () => import("../views/mall/ShopGoods.vue")
      },
      {
        path: "/order",
        name: "order",
        component: () => import("../views/mall/Order.vue")
      },
      {
        path: "/comment",
        name: "comment",
        component: () => import("../views/mall/Comment.vue")
      },
      {
        path: "/changeRecord",
        name: "changeRecord",
        component: () => import("../views/mall/ChangeRecord.vue")
      },
      {
        path: "/scoreRecord",
        name: "scoreRecord",
        component: () => import("../views/mall/ScoreRecord.vue")
      },
      {
        path: "/articleCate",
        name: "articleCate",
        component: () => import("../views/mall/ArticleCate.vue")
      },
      {
        path: "/articleList",
        name: "articleList",
        component: () => import("../views/mall/ArticleList.vue")
      },
      {
        path: "/articleCate2",
        name: "articleCate2",
        component: () => import("../views/mall/ArticleCate2.vue")
      },
      {
        path: "/articleList2",
        name: "articleList2",
        component: () => import("../views/mall/ArticleList2.vue")
      },
      {
        path: "/articleCate3",
        name: "articleCate3",
        component: () => import("../views/mall/ArticleCate3.vue")
      },
      {
        path: "/articleList3",
        name: "articleList3",
        component: () => import("../views/mall/ArticleList3.vue")
      },
      {
        path: "/scoreGoods",
        name: "scoreGoods",
        component: () => import("../views/mall/ScoreGoods.vue")
      },
      {
        path: "/scoreOrder",
        name: "scoreOrder",
        component: () => import("../views/mall/ScoreOrder.vue")
      },
      {
        path: "/shopExpress",
        name: "shopExpress",
        component: () => import("../views/mall/ShopExpress.vue")
      },
      {
        path: "/scoreGoodsCate",
        name: "scoreGoodsCate",
        component: () => import("../views/mall/ScoreGoodsCate.vue")
      },
      {
        path: "/coupon",
        name: "coupon",
        component: () => import("../views/this/Coupon.vue")
      },
      {
        path: "/couponRecord",
        name: "couponRecord",
        component: () => import("../views/this/CouponRecord.vue")
      },
      {
        path: "/sportscatalogue",
        name: "sportscatalogue",
        component: () => import("../views/this/SportsCatalogue2.vue")
      },
      {
        path: "/sportevent",
        name: "sportevent",
        component: () => import("../views/this/SportEvent.vue")
      },
      {
        path: "/cooperate",
        name: "cooperate",
        component: () => import("../views/this/Cooperate.vue")
      },
      {
        path: "/viewrecord",
        name: "viewrecord",
        component: () => import("../views/this/ViewRecord.vue")
      },
      {
        path: "/likeRecord",
        name: "likeRecord",
        component: () => import("../views/this/LikeRecord.vue")
      },
      {
        path: "/feedback",
        name: "feedback",
        component: () => import("../views/this/Feedback.vue")
      },
      
      {
        path: "/selfcate",
        name: "selfcate",
        component: () => import("../views/other/SelfCate.vue")
      },
      {
        path: "/self",
        name: "self",
        component: () => import("../views/other/SelfRescue.vue")
      },
      {
        path: "/staff",
        name: "staff",
        component: () => import("../views/other/StaffList.vue")
      },
      {
        path: "/staff_goods",
        name: "staff_goods",
        component: () => import("../views/other/Staff_Goods.vue")
      },
      {
        path: "/event_goods",
        name: "event_goods",
        component: () => import("../views/other/Event_Goods.vue")
      },
      {
        path: "/event_cooperate",
        name: "event_cooperate",
        component: () => import("../views/other/Event_Cooperate.vue")
      },
      {
        path: "/catalogedit",
        name: "catalogedit",
        component: () => import("../views/other/CatalogEdit.vue")
      },
      {
        path: "/graded",
        name: "graded",
        component: () => import("../views/other/GradedControl.vue")
      },
      {
        path: "/gradedsign",
        name: "gradedsign",
        component: () => import("../views/other/GradedSign.vue")
      },
      {
        path:"/satisfaction",
        name:"satisfaction",
        component:()=>import("../views/this/Satisfaction.vue")
      },
      //设备管理页面
      {
        path:"/basedevice",
        name:"basedevice",
        component:()=>import("../views/this/Basedevice.vue")
      },
      //比赛记录
      {
        path:"/eventRecord",
        name:"eventRecord",
        component:()=>import("../views/this/EventRecord.vue")
      },


    ] 
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue")
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
